/** @jsxImportSource @emotion/react */
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ADMIN_ROUTES } from '../../../constants';
import { useTelegram } from '../../../hooks/useTelegram';
import { UserManagementForm } from './userManagementForm/UserManagementForm';
import { usersApi } from '../../../api/usersApi';
import styles from './styles';

export function UserManagementProfileLayout() {
    const { id } = useParams();

    const { isLoading, data: user } = usersApi.useOneUserQuery({ telegramUserId: Number(id) });

    const { colorScheme } = useTelegram();

    const navigate = useNavigate();

    const handleGoBackClick = () => {
        navigate(ADMIN_ROUTES.USERS_LIST);
    };

    if (!id) {
        return <Navigate to={ADMIN_ROUTES.USERS_LIST} />;
    }

    if (isLoading) {
        return (
            <Stack justifyContent="center" alignItems="center">
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <Box p={2.5}>
            <Stack p={2} gap={2}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <IconButton onClick={handleGoBackClick}>
                        <ArrowBackIcon />
                    </IconButton>
                    <h3 css={styles.headerTextStyles({ colorScheme })}>Change User Info</h3>
                </Stack>
                {user && <UserManagementForm user={user.data[0]} />}
            </Stack>
        </Box>
    );
}
