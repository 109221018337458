import { z } from 'zod';
import {
    NAME_MAX_LENGTH,
    STRING_TYPE_ALPHABETIC,
    SURNAME_MAX_LENGTH,
    USER_TEAM,
} from '../../../../constants';

export const userProfileFormSchema = z.object({
    name: z
        .string()
        .max(NAME_MAX_LENGTH, `Name must be maximum ${NAME_MAX_LENGTH} characters`)
        .regex(STRING_TYPE_ALPHABETIC, 'Only letters are available'),
    surname: z
        .string()
        .max(SURNAME_MAX_LENGTH, `Surname must be maximum ${SURNAME_MAX_LENGTH} characters`)
        .regex(STRING_TYPE_ALPHABETIC, 'Only letters are available'),
    isAdmin: z.boolean().optional(),
    team: z.array(z.nativeEnum(USER_TEAM)).optional(),
});

export type UserProfileFormSchema = z.infer<typeof userProfileFormSchema>;
