/* eslint-disable jsx-a11y/no-static-element-interactions */

/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useGetBookedSpace } from '../../../../hooks/useGetBookedSpace';
import { useTelegram } from '../../../../hooks/useTelegram';
import { setOfficeSpace } from '../../../../store/reducers/officeSpace';
import { User } from '../../../../store/reducers/user';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface OfficeSpaceProps {
    value: string;
    isMeetingRoom: boolean;
    editWorkPlaceActive: boolean;
    setModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMyBooking: React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: User | null;
    startDateState?: string;
    endDateState?: string;
    setOtherUsersHaveBookingsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    isBlocked?: boolean;
    blockedUserName?: string;
}

function OfficeSpace({
    value,
    isMeetingRoom,
    editWorkPlaceActive,
    setModalActive,
    setIsMyBooking,
    userInfo,
    startDateState,
    endDateState,
    setOtherUsersHaveBookingsModalActive,
    blockedUserName,
    isBlocked,
}: OfficeSpaceProps) {
    const bookedSpaces = useAppSelector((store) => store.officeSpaceReducer.bookedSpaces);

    const { tgUserId, colorScheme } = useTelegram();

    const dispatch = useDispatch();

    const { isBooked, bookedSpaceInfo, isMyBook } = useGetBookedSpace(
        value,
        bookedSpaces,
        tgUserId,
    );

    const isUserBooked = bookedSpaces.some((s) => s.spaceUser.telegramUserId === tgUserId);

    const isUserHaveBook = bookedSpaces.some(
        (s) => s.spaceUser.telegramUserId === tgUserId && s.name === value,
    );

    const editedSpace = useAppSelector((store) => store.officeSpaceReducer.editedUserSpace);

    const chosenStartDate = dayjs(startDateState);

    const chosenEndDate = dayjs(endDateState);

    const sameBooking = bookedSpaces.find((s) => {
        if (s.userSpaceId !== editedSpace?.id && s.name === value && editedSpace?.name === s.name) {
            const bookedStartDate = dayjs(s.startDate);
            const bookedEndDate = dayjs(s.endDate);

            // @ts-ignore
            const isStartDateBetween = chosenStartDate.isBetween(
                bookedStartDate,
                bookedEndDate,
                '[]',
            );

            // @ts-ignore
            const isEndTimeBetween = chosenEndDate.isBetween(bookedStartDate, bookedEndDate, '[]');

            const isStartDateSame =
                (chosenStartDate.get('date') === bookedStartDate.get('date') &&
                    chosenStartDate.get('month') === bookedStartDate.get('month') &&
                    chosenStartDate.get('year') === bookedStartDate.get('year')) ||
                (chosenStartDate.get('date') === bookedEndDate.get('date') &&
                    chosenStartDate.get('month') === bookedEndDate.get('month') &&
                    chosenStartDate.get('year') === bookedEndDate.get('year'));

            const isEndDateSame =
                (chosenEndDate.get('date') === bookedStartDate.get('date') &&
                    chosenEndDate.get('month') === bookedStartDate.get('month') &&
                    chosenEndDate.get('year') === bookedStartDate.get('year')) ||
                (chosenEndDate.get('date') === bookedEndDate.get('date') &&
                    chosenEndDate.get('month') === bookedEndDate.get('month') &&
                    chosenEndDate.get('year') === bookedEndDate.get('year'));

            if (isStartDateBetween || isEndTimeBetween || isStartDateSame || isEndDateSame) {
                return s;
            }
        }

        return undefined;
    });

    useEffect(() => {
        if (sameBooking) {
            setOtherUsersHaveBookingsModalActive(true);
        }
    }, [sameBooking]);

    const isSpaceEdited = editedSpace?.name === value && !sameBooking && editWorkPlaceActive;

    const isSpaceClickable =
        (!isBlocked && !isBooked && !isUserHaveBook && !isUserBooked && !isMeetingRoom) ||
        (!isBlocked && editWorkPlaceActive && !isBooked) ||
        (!isBlocked && isMyBook && !sameBooking) ||
        (!isBlocked && isSpaceEdited && !isBooked);

    const onClickHandler = () => {
        if (isMyBook) {
            if (!editWorkPlaceActive) {
                setModalActive(true);
            }
            setIsMyBooking(true);
            if (editWorkPlaceActive) {
                dispatch(setOfficeSpace({ newSpace: value }));
            }
        } else {
            dispatch(setOfficeSpace({ newSpace: value }));
            setIsMyBooking(false);
        }

        if (!isMyBook && isSpaceEdited) {
            setIsMyBooking(true);
            if (editWorkPlaceActive) {
                dispatch(setOfficeSpace({ newSpace: value }));
            }
        }
    };

    const isChosenSpace = useAppSelector((store) => store.officeSpaceReducer.chosenSpace) === value;
    return isSpaceClickable ? (
        <div
            onClick={onClickHandler}
            css={styles.spaceStyles({
                isUserBooked,
                isBooked,
                isMyBook: (isMyBook && !sameBooking) || (isSpaceEdited && !isBooked),
                isMeetingRoom,
                editWorkPlaceActive,
                colorScheme,
                isChosenSpace,
            })}
            className="officeSpace"
        >
            {isBooked || isSpaceEdited ? (
                <div className="userName">
                    {isBooked && (
                        <>
                            <p>{bookedSpaceInfo?.spaceUser.name}</p>
                            <p>{bookedSpaceInfo?.spaceUser.surname}</p>
                        </>
                    )}
                    {isSpaceEdited && !isBooked && (
                        <>
                            <p>{userInfo?.name}</p>
                            <p>{userInfo?.surname}</p>
                        </>
                    )}
                </div>
            ) : (
                <p className="spaceName">{value}</p>
            )}
        </div>
    ) : (
        <div
            css={styles.spaceStyles({
                isUserBooked,
                isBooked,
                isMyBook: false,
                isMeetingRoom,
                editWorkPlaceActive,
                colorScheme,
                isChosenSpace,
                isBlocked: Boolean(isBlocked),
            })}
            className="officeSpace"
        >
            {isBooked || (isBlocked && blockedUserName && !isMeetingRoom) ? (
                <div className="userName">
                    {sameBooking && !isBlocked ? (
                        <>
                            <p>{sameBooking.spaceUser.name}</p>
                            <p>{sameBooking.spaceUser.surname}</p>
                        </>
                    ) : (
                        <>
                            <p>{bookedSpaceInfo?.spaceUser.name}</p>
                            <p>{bookedSpaceInfo?.spaceUser.surname}</p>
                        </>
                    )}
                    {isBlocked && <p>{blockedUserName}</p>}
                </div>
            ) : (
                <p className="spaceName">{value}</p>
            )}
        </div>
    );
}

export default OfficeSpace;
