import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type ControlledClientAutocompleteProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    options: { label: string; value: string }[];
    label?: string;
};

export function ControlledClientAutocomplete<T extends FieldValues>({
    control,
    name,
    options,
    label,
}: ControlledClientAutocompleteProps<T>) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                        typeof value === 'string'
                            ? option.value === value
                            : option.value === value?.value
                    }
                    renderInput={(props) => <TextField {...props} label={label} />}
                    onChange={(_, selectedOption) => {
                        field.onChange(selectedOption ? selectedOption.value : '');
                    }}
                    value={options.find((option) => option.value === field.value) || null}
                />
            )}
        />
    );
}
