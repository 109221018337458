import { Close } from '@mui/icons-material';
import { Drawer, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { ControlledDateRangePicker } from '../../../common/ControlledDateRangePicker';
import { addNewBookingSchema, AddNewBookingSchema } from './addNewBooking.schema';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useSnackbarContext } from '../../../common/SnackbarProvider/SnackbarProvider';
import { MapConfig, OfficeMap } from '../../../common/OfficeMap/OfficeMap';
import { officeSpaceBookingApi } from '../../../../api/officeSpaceBookingApi';
import { ControlledCheckbox } from '../../../common/ControlledCheckbox/ControlledCheckbox';
import { ControlledClientAutocomplete } from '../../../common/ControlledClientAutocomplete';

type AddNewBookingsDrawerProps = {
    open: boolean;
    onClose: () => void;
    usersSelectOptions: { label: string; value: string }[];
};

const officeMapConfig: MapConfig = [
    { id: 'MR1', options: { disabled: true } },
    { id: 'MR2', options: { disabled: true } },
    { id: 'MR3', options: { disabled: true } },
];

export const AddNewBookingsDrawer: React.FC<AddNewBookingsDrawerProps> = ({
    onClose,
    open,
    usersSelectOptions,
}) => {
    const [bookOfficeSpace, { isLoading }] = officeSpaceBookingApi.useCreateMutation();

    const [activeStep, setActiveStep] = useState<number>(0);

    const { queryId } = useTelegram();

    const { showSnackbar } = useSnackbarContext();

    const {
        control,
        watch,
        setValue,
        getValues,
        handleSubmit,
        reset,
        formState: { dirtyFields },
    } = useForm<AddNewBookingSchema>({
        resolver: zodResolver(addNewBookingSchema),
        defaultValues: {
            userId: undefined,
            endDate: new Date().toISOString(),
            startDate: new Date().toISOString(),
        },
    });

    const handleCloseDrawer = () => {
        setActiveStep(0);
        reset();
        onClose();
    };

    const onSubmit: SubmitHandler<AddNewBookingSchema> = async (data) => {
        await bookOfficeSpace({
            queryId: queryId || '',
            spaceName: data.spaceName,
            startDate: data.startDate!,
            userId: data.userId,
            endDate: data.isRepeatAlways ? null : data.endDate,
            permanent: data.isRepeatAlways,
        })
            .unwrap()
            .then(() => {
                showSnackbar('Office Space Booked Successfully', 'success');
                reset();
                handleCloseDrawer();
            })
            .catch((error: { status: number; code: string }) => {
                if (error.status === 403) {
                    showSnackbar("User's team can not book this office space", 'error');
                }
                if (error.status === 404) {
                    showSnackbar('User or office space does not exist', 'error');
                }
                if (error.status === 409) {
                    showSnackbar(
                        'This user has another active booking for this time period',
                        'error',
                    );
                }
                if (error.status === 500) {
                    showSnackbar('Internal Server Error', 'error');
                }
            });
    };

    const handleNextStep = () => {
        setActiveStep((prev) => prev + 1);
    };

    const handleOfficeSpaceClick = (value: string) => {
        setValue('spaceName', value, { shouldDirty: true });
    };

    const handleButtonClick = activeStep === 0 ? handleNextStep : handleSubmit(onSubmit);

    const isSubmitButtonEnabled =
        dirtyFields.userId || (dirtyFields.userId && dirtyFields.isRepeatAlways);

    return (
        <Drawer open={open} onClose={handleCloseDrawer}>
            <Stack gap={1} p={3} sx={{ width: '100vw', height: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h5">Add New Booking</Typography>
                    <Close onClick={handleCloseDrawer} sx={{ cursor: 'pointer' }} />
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                    <Stack height="100%" gap={2}>
                        {activeStep === 0 && (
                            <Stack gap={2} flexGrow={1}>
                                <ControlledClientAutocomplete
                                    control={control}
                                    name="userId"
                                    options={usersSelectOptions}
                                    label="User"
                                />

                                <ControlledDateRangePicker
                                    control={control}
                                    watch={watch}
                                    setValue={setValue}
                                    getValues={getValues}
                                    config={[
                                        {
                                            name: 'startDate',
                                            label: 'Start Date',
                                            minDate: new Date().toISOString(),
                                        },
                                        {
                                            name: 'endDate',
                                            label: 'End Date',
                                            minDate: watch('startDate') ?? undefined,
                                            disabled: watch('isRepeatAlways'),
                                        },
                                    ]}
                                />
                                <ControlledCheckbox
                                    control={control}
                                    name="isRepeatAlways"
                                    label="Permanent Booking"
                                />
                            </Stack>
                        )}
                        {activeStep === 1 && (
                            <OfficeMap
                                applyDefaultConfig
                                config={officeMapConfig}
                                selectedSpace={watch('spaceName')}
                                onOfficeSpaceClick={handleOfficeSpaceClick}
                                startDate={getValues('startDate')}
                                endDate={
                                    getValues('isRepeatAlways') ? undefined : getValues('endDate')
                                }
                            />
                        )}
                        <Stack>
                            <LoadingButton
                                onClick={handleButtonClick}
                                disabled={!isSubmitButtonEnabled}
                                variant="contained"
                                loading={isLoading}
                                type={activeStep === 0 ? 'button' : 'submit'}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </Drawer>
    );
};
