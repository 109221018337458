/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const headerTextStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
        marginBottom: '15px',
    });
};

const styles = {
    headerTextStyles,
};

export default styles;
