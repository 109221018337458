// Замените на ваш путь к snackActions
import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { setUserAuth } from '../reducers/user';
import { STATUS_CODE } from '../../constants';

export const rtkQueryErrorMiddleware: Middleware = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const rejectedValue = action.payload;
        if (rejectedValue.status === STATUS_CODE.UNAUTHORIZED) {
            api.dispatch(
                setUserAuth({
                    isAuth: false,
                    email: '',
                }),
            );
        }
    }
    return next(action);
};
