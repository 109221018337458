/** @jsxImportSource @emotion/react */
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { OfficeMap, MapConfig } from '../../common/OfficeMap/OfficeMap';
import { useTelegram } from '../../../hooks/useTelegram';
import { DeskManagementDrawer } from './DeskManagementDrawer';
import { officeSpaceApi } from '../../../api/officeSpaceApi';
import { FullPageLoader } from '../../common/FullPageLoader/FullPageLoader';
import { OfficeSpaceListResponse } from '../../../api/types';
import { TeamAssignmentList } from './TeamAssignmentList/TeamAssignmentList';
import styles from './TeamAssignmentList/TeamAssignmentItem/styles';

export function DeskManagementLayout() {
    const [selectedSpace, setSelectedSpace] = useState<string>('');
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [mapConfig, setMapConfig] = useState<MapConfig>([]);

    const { data, isLoading } = officeSpaceApi.useListQuery({});

    const { colorScheme, tg } = useTelegram();

    const handleOfficeSpaceClick = (value: string) => {
        setSelectedSpace(value);
    };

    useEffect(() => {
        tg.onEvent('mainButtonClicked', handleOpenDrawer);
        return () => {
            tg.offEvent('mainButtonClicked', handleOpenDrawer);
        };
    }, []);

    useEffect(() => {
        if (!!selectedSpace && !isDrawerOpen) {
            tg.MainButton.show();
            tg.MainButton.setParams({
                text: `Update Desk ${selectedSpace}`,
            });
        } else {
            tg.MainButton.hide();
        }
    });

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleOpenDrawer = () => {
        setIsDrawerOpen(true);
    };

    useEffect(() => {
        if (data) {
            const mappedResponse: MapConfig = mapResponseToMapConfig(data.data);
            setMapConfig(mappedResponse);
        }
    }, [data]);

    if (isLoading) {
        return <FullPageLoader />;
    }

    return (
        <Box
            p={3}
            bgcolor={colorScheme === 'dark' ? '#1F2023' : '#FFFFFF'}
            height="100vh"
            css={styles.wrapperStyles({ colorScheme })}
        >
            <Stack direction="column" justifyContent="space-between" height="100%">
                <Stack direction="column" gap={1.5} flex={1}>
                    <Box
                        p={1}
                        color={colorScheme === 'dark' ? '#FFF' : '#000'}
                        bgcolor={colorScheme === 'dark' ? '#27292D' : '#E8E8E8'}
                    >
                        <h3 css={styles.headerTextStyles}>Update Desk Settings</h3>
                    </Box>
                    <TeamAssignmentList />
                    <OfficeMap
                        key={JSON.stringify(mapConfig)}
                        onOfficeSpaceClick={handleOfficeSpaceClick}
                        selectedSpace={selectedSpace}
                        config={mapConfig}
                    />
                </Stack>
            </Stack>
            <DeskManagementDrawer
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
                value={selectedSpace}
            />
        </Box>
    );
}

const mapResponseToMapConfig = (data: OfficeSpaceListResponse['data']) => {
    return data.map((item) => ({
        id: item.name,
        options: {
            spaceTeams: item.bookingTeams?.map((team) => team),
        },
    }));
};
