import { Close } from '@mui/icons-material';
import { Button, Drawer, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import {
    manageDeskBookingsFiltersSchema,
    ManageDeskBookingsFiltersSchema,
} from './manageDeskBookingsFilters.schema';
import { ControlledSelect } from '../../../common/ControlledSelect/ControlledSelect';
import { ControlledDatePicker } from '../../../common/ControlledDatePicker/ControlledDatePicker';
import {} from '../../../../hooks/useMainButtonForSubmit';
import { defaultFilters } from '../constants';
import { ControlledClientAutocomplete } from '../../../common/ControlledClientAutocomplete';

type DeskBookingsFiltersDrawerProps = {
    open: boolean;
    onClose: () => void;
    onFiltersChange: (values: ManageDeskBookingsFiltersSchema) => void;
    filters: ManageDeskBookingsFiltersSchema;
    usersSelectOptions: { label: string; value: string }[];
    officeSpacesSelectOptions: { label: string; value: string }[];
};

export const DeskBookingsFiltersDrawer: React.FC<DeskBookingsFiltersDrawerProps> = ({
    onClose,
    open,
    filters,
    onFiltersChange,
    officeSpacesSelectOptions,
    usersSelectOptions,
}) => {
    const { control, handleSubmit, getValues, watch, setValue, reset, formState } =
        useForm<ManageDeskBookingsFiltersSchema>({
            resolver: zodResolver(manageDeskBookingsFiltersSchema),
            defaultValues: {
                ...filters,
            },
        });

    const onSubmit: SubmitHandler<ManageDeskBookingsFiltersSchema> = (data) => {
        onFiltersChange(data);
        reset(data);
        onClose();
    };

    const handleReset = () => {
        reset(defaultFilters);
        onFiltersChange(defaultFilters);
    };

    const isSubmitButtonDisabled = !Object.keys(formState.dirtyFields).length;
    const isResetButtonDisabled = JSON.stringify(defaultFilters) === JSON.stringify(getValues());

    useEffect(() => {
        const startDate = getValues('startDate');
        const endDate = getValues('endDate');

        const isEndDateBefore = dayjs(endDate).isBefore(startDate);

        if ((startDate && !endDate) || isEndDateBefore) {
            setValue('endDate', startDate, { shouldDirty: true });
        }
    }, [watch('startDate'), watch('endDate')]);

    return (
        <Drawer open={open} onClose={onClose}>
            <Stack gap={1} p={3} sx={{ width: '100vw', height: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">Filters</Typography>
                    <Close onClick={onClose} />
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                    <Stack height="100%">
                        <Stack gap={2} flexGrow={1}>
                            <ControlledClientAutocomplete
                                control={control}
                                options={usersSelectOptions}
                                name="userId"
                                label="User"
                            />
                            <ControlledSelect
                                control={control}
                                label="Desk"
                                name="deskNumber"
                                options={officeSpacesSelectOptions}
                            />
                            <ControlledDatePicker
                                control={control}
                                minDate={new Date().toISOString()}
                                name="startDate"
                                label="Start Date"
                            />
                            <ControlledDatePicker
                                control={control}
                                minDate={watch('startDate')}
                                maxDate={dayjs().add(1, 'year').toString()}
                                name="endDate"
                                label="End Date"
                            />
                        </Stack>
                        <Stack gap={1}>
                            <Button
                                type="submit"
                                disabled={isSubmitButtonDisabled}
                                variant="contained"
                            >
                                Apply
                            </Button>
                            <Button
                                onClick={handleReset}
                                disabled={isResetButtonDisabled}
                                variant="outlined"
                                color="info"
                            >
                                Reset Filters
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </Drawer>
    );
};
