/* eslint-disable jsx-a11y/no-static-element-interactions */

/** @jsxImportSource @emotion/react */
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import { Box, Stack, Tooltip } from '@mui/material';
import RepeatIcon from '../../../assets/repeatIcon';
import { generateWeekViewCoordinates } from '../../../utils/generateWeekViewCoordinates';
import { useTelegram } from '../../../hooks/useTelegram';
import { MAX_TIME, MIN_TIME, REPEAT_VALUE } from '../../../constants';

import styles from './styles';
import { ApplicationUser, MeetingRoomBooking } from '../../../types';

const MINUTES_IN_HOUR = 60;
const TWENTY_FIVE_MINUTES = 25;

interface BookedTimeCellProps {
    editable?: boolean;
    allMeetingRooms?: boolean;
    meetingRoomName: string;
    booking: MeetingRoomBooking;
    user: ApplicationUser;
    onClick?: () => void;
    onEditClick?: (booking: MeetingRoomBooking) => void;
    onDeleteClick?: (bookingId: string) => void;
}

function BookedTimeCell({
    editable,
    allMeetingRooms,
    meetingRoomName,
    booking,
    onClick,
    user,
    onDeleteClick,
    onEditClick,
}: BookedTimeCellProps) {
    const { colorScheme, tgUserId } = useTelegram();

    const bookingStartDateTime = dayjs(`${booking.date} ${booking.startTime}`);
    const bookingEndDateTime = dayjs(`${booking.date} ${booking.endTime}`);

    const bookingDuration = bookingEndDateTime.diff(bookingStartDateTime, 'minutes');

    const startTimeHour = bookingStartDateTime.get('hour');
    const startTimeMinutes = bookingStartDateTime.get('minutes');
    const startTimeString = bookingStartDateTime.format('HH:mm a');

    const endTimeHour = bookingEndDateTime.get('hour');
    const endTimeMinutes = bookingEndDateTime.get('minutes');
    const endTimeString = bookingEndDateTime.format('HH:mm a');

    const isMoreThanHour = bookingDuration > MINUTES_IN_HOUR;
    const isOneHour = bookingDuration === MINUTES_IN_HOUR;
    const isMyBook = user.telegramUserId === tgUserId;

    const first = startTimeHour === MIN_TIME && startTimeMinutes === 0;
    const last = endTimeHour === MAX_TIME && endTimeMinutes === 0;

    const repeatValue = getRepeatValueLabel(booking.repeatValue as REPEAT_VALUE);

    const weekViewCoordinates = generateWeekViewCoordinates({
        date: booking.date,
        startTime: booking.startTime,
        endTime: booking.endTime,
        meetingRoomName,
        allMeetingRooms,
    });

    return onClick ? (
        <Tooltip
            title={
                bookingDuration <= TWENTY_FIVE_MINUTES && booking.isRepeated
                    ? `${user.name} ${user.surname}`
                    : ''
            }
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
                        color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                        fontWeight: 400,
                        fontSize: '8px',
                        bottom: '10px',
                        left: '5px',
                        position: 'relative',
                    },
                },
                popper: {
                    sx: {
                        height: '10px',
                    },
                },
            }}
        >
            <div
                style={weekViewCoordinates}
                css={styles.bookedRowStyles({
                    isMoreThanHour,
                    colorScheme,
                    isOneHour,
                    isMyBook,
                    first,
                    last,
                    allMeetingRooms,
                    meetingRoomName,
                    bookingMinutes: bookingDuration,
                })}
                onClick={onClick}
            >
                <div
                    css={styles.bookInfoWrapperStyles({
                        isOneHour,
                        isMoreThanHour,
                        allMeetingRooms,
                        bookingMinutes: bookingDuration,
                    })}
                >
                    {bookingDuration > TWENTY_FIVE_MINUTES && (
                        <p
                            css={styles.nameTextStyles({
                                isMoreThanHour,
                                allMeetingRooms,
                                bookingMinutes: bookingDuration,
                                isRepeated: Boolean(booking.isRepeated),
                            })}
                        >
                            {user.name} {user.surname}
                        </p>
                    )}
                    <p
                        css={styles.bookedTimeWrapper({
                            isMoreThanHour,
                            allMeetingRooms,
                            bookingMinutes: bookingDuration,
                            isRepeated: Boolean(booking.isRepeated),
                        })}
                    >
                        {startTimeString} - {endTimeString}
                    </p>
                </div>
                {!!repeatValue && (
                    <div
                        css={styles.repeatInfoWrapperStyles({
                            isOneHour,
                            isMoreThanHour,
                            allMeetingRooms,
                            bookingMinutes: bookingDuration,
                        })}
                    >
                        <RepeatIcon
                            color={colorScheme === 'dark' ? '#FFFFFF' : '#FFFFFF'}
                            repeatIconStyles={styles.repeatIconsStyles({
                                colorScheme,
                                isMoreThanHour,
                                allMeetingRooms,
                                bookingMinutes: bookingDuration,
                            })}
                        />

                        <p
                            css={styles.repeatValueTextStyles({
                                isMoreThanHour,
                                allMeetingRooms,
                                bookingMinutes: bookingDuration,
                            })}
                        >
                            {repeatValue}
                        </p>
                    </div>
                )}
            </div>
        </Tooltip>
    ) : (
        <Tooltip
            title={
                bookingDuration <= TWENTY_FIVE_MINUTES && booking.isRepeated
                    ? `${user.name} ${user.surname}`
                    : ''
            }
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
                        color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                        fontWeight: 400,
                        fontSize: '8px',
                        bottom: '10px',
                        left: '5px',
                        position: 'relative',
                    },
                },
                popper: {
                    sx: {
                        height: '10px',
                    },
                },
            }}
        >
            <div
                style={weekViewCoordinates}
                css={styles.bookedRowStyles({
                    isMoreThanHour,
                    colorScheme,
                    isOneHour,
                    isMyBook,
                    first,
                    last,
                    allMeetingRooms,
                    meetingRoomName,
                    bookingMinutes: bookingDuration,
                })}
            >
                <Stack height="100%" justifyContent="space-between" width="100%">
                    <Box>
                        <div
                            css={styles.bookInfoWrapperStyles({
                                isOneHour,
                                isMoreThanHour,
                                allMeetingRooms,
                                bookingMinutes: bookingDuration,
                            })}
                        >
                            {bookingDuration > TWENTY_FIVE_MINUTES && (
                                <p
                                    css={styles.nameTextStyles({
                                        isMoreThanHour,
                                        allMeetingRooms,
                                        bookingMinutes: bookingDuration,
                                        isRepeated: Boolean(booking.isRepeated),
                                    })}
                                >
                                    {user.name} {user.surname}
                                </p>
                            )}
                            <p
                                css={styles.bookedTimeWrapper({
                                    isMoreThanHour,
                                    allMeetingRooms,
                                    bookingMinutes: bookingDuration,
                                    isRepeated: Boolean(booking.isRepeated),
                                })}
                            >
                                {startTimeString} - {endTimeString}
                            </p>
                        </div>
                        {!!repeatValue && (
                            <div
                                css={styles.repeatInfoWrapperStyles({
                                    isOneHour,
                                    isMoreThanHour,
                                    allMeetingRooms,
                                    bookingMinutes: bookingDuration,
                                })}
                            >
                                <RepeatIcon
                                    color={colorScheme === 'dark' ? '#FFFFFF' : '#FFFFFF'}
                                    repeatIconStyles={styles.repeatIconsStyles({
                                        colorScheme,
                                        isMoreThanHour,
                                        allMeetingRooms,
                                        bookingMinutes: bookingDuration,
                                    })}
                                />
                                <p
                                    css={styles.repeatValueTextStyles({
                                        isMoreThanHour,
                                        allMeetingRooms,
                                        bookingMinutes: bookingDuration,
                                    })}
                                >
                                    {repeatValue}
                                </p>
                            </div>
                        )}
                    </Box>
                    {editable && (
                        <Stack direction="row" justifyContent="flex-end" gap={2} mb={1}>
                            <EditIcon onClick={() => onEditClick?.(booking)} />
                            <DeleteIcon onClick={() => onDeleteClick?.(booking.id)} />
                        </Stack>
                    )}
                </Stack>
            </div>
        </Tooltip>
    );
}

export default BookedTimeCell;

function getRepeatValueLabel(repeatValue?: REPEAT_VALUE | null): string {
    let label = '';

    if (repeatValue === REPEAT_VALUE.DAY) {
        label = 'Daily';
    }

    if (repeatValue === REPEAT_VALUE.WEEK) {
        label = 'Weekly';
    }

    if (repeatValue === REPEAT_VALUE.MONTH) {
        label = 'Monthly';
    }

    return label;
}
