import { Close } from '@mui/icons-material';
import { Drawer, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { OfficeMap } from '../../../common/OfficeMap/OfficeMap';
import { useSnackbarContext } from '../../../common/SnackbarProvider/SnackbarProvider';
import { officeSpaceBookingApi } from '../../../../api/officeSpaceBookingApi';

type UpdateBookingDrawerProps = {
    open: boolean;
    bookingId: string;
    onClose: () => void;
};

export const UpdateBookingDrawer: React.FC<UpdateBookingDrawerProps> = ({
    onClose,
    open,
    bookingId,
}) => {
    const [selectedSpace, setSelectedSpace] = useState<string>('');
    const [bookingStartDate, setBookingStartDate] = useState<string>('');
    const [bookingEndDate, setBookingEndDate] = useState<string | null>('');

    const [getBookings] = officeSpaceBookingApi.useLazyListQuery();
    const [updateBooking, { isLoading }] = officeSpaceBookingApi.useUpdateMutation();

    const { showSnackbar } = useSnackbarContext();

    const handleOfficeSpaceClick = (value: string) => {
        setSelectedSpace(value);
    };

    const handleClose = () => {
        setSelectedSpace('');
        onClose();
    };

    const handleSubmit = async () => {
        updateBooking({
            bookingId,
            spaceName: selectedSpace,
        })
            .unwrap()
            .then(() => {
                showSnackbar('Booking was successfully updated', 'success');
                handleClose();
            })
            .catch((error: { status: number }) => {
                if (error.status === 403) {
                    showSnackbar("User's team can not book this office space", 'error');
                }
            });
    };

    useEffect(() => {
        if (bookingId) {
            const fetchBookings = async () => {
                try {
                    const { data } = await getBookings({ filters: { id: [bookingId] } }).unwrap();
                    const startDate = data[0].startDate;
                    const endDate = data[0].endDate;

                    setBookingStartDate(startDate);
                    setBookingEndDate(endDate);
                } catch {
                    showSnackbar('Error occured while getting a booking', 'error');
                }
            };

            fetchBookings();
        }
    }, [bookingId]);

    return (
        <Drawer open={open} onClose={handleClose}>
            <Stack gap={1} p={3} width="100vw" height="100%">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography>Update Booking</Typography>
                    <Close onClick={handleClose} />
                </Stack>
                <Stack flexGrow={1}>
                    <OfficeMap
                        selectedSpace={selectedSpace}
                        onOfficeSpaceClick={handleOfficeSpaceClick}
                        startDate={bookingStartDate}
                        endDate={bookingEndDate}
                    />
                </Stack>
                <LoadingButton
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={!selectedSpace}
                    variant="contained"
                >
                    Submit
                </LoadingButton>
            </Stack>
        </Drawer>
    );
};
