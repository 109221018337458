/** @jsxImportSource @emotion/react */
import { useTelegram } from '../../../hooks/useTelegram';
import { useAppSelector } from '../../../hooks/useAppSelector';

import styles from './styles';

import { UserProfileForm } from './userProfileForm/UserProfileForm';

function UserProfile() {
    const { colorScheme } = useTelegram();

    const userInfo = useAppSelector((store) => store.userReducer.user);

    return (
        <div css={styles.formStyles({ colorScheme })}>
            <h3 css={styles.headerTextStyles({ colorScheme })}>Change your info</h3>
            {userInfo && <UserProfileForm user={userInfo} />}
        </div>
    );
}

export default UserProfile;
