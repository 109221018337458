/** @jsxImportSource @emotion/react */
import { FormControlLabel, FormGroup, Checkbox, Typography, Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { NAME_MAX_LENGTH, SURNAME_MAX_LENGTH, USER_TEAM } from '../../../../constants';
import { GetUsersResponse } from '../../../../api/types';
import { useTelegram } from '../../../../hooks/useTelegram';
import { ControlledSwitch } from '../../../common/ControlledSwitch/ControlledSwitch';
import { Input } from '../../../common/input';
import { usersApi } from '../../../../api/usersApi';
import { userProfileFormSchema, UserProfileFormSchema } from './userProfileForm.schema';
import styles from '../styles';

type UserManagementFormProps = {
    user: GetUsersResponse['data'][number];
};

export function UserProfileForm({ user }: UserManagementFormProps) {
    const { tg, queryId, colorScheme } = useTelegram();
    const [userTeams, setUserTeams] = useState<USER_TEAM[] | null>([]);

    const { handleSubmit, register, formState, setValue, control } = useForm<UserProfileFormSchema>(
        {
            resolver: zodResolver(userProfileFormSchema),
            defaultValues: {
                name: user?.name || '',
                surname: user?.surname || '',
            },
        },
    );

    const { dirtyFields, errors } = formState;

    const [updateUser] = usersApi.useUpdateMutation();

    useEffect(() => {
        if (user) {
            setValue('name', user.name);
            setValue('surname', user.surname);
            setValue('isAdmin', user.isAdmin);
            setUserTeams(user.team);
        }
    }, [user]);

    const onSubmit: SubmitHandler<any> = async (formData): Promise<void> => {
        const { name, surname } = formData;

        if (user && queryId) {
            const data = {
                id: user.id,
                name,
                surname,
                queryId,
            };

            updateUser(data)
                .unwrap()
                .then(() => {
                    tg.close();
                });
        }
    };

    const sendData = handleSubmit(onSubmit);

    useEffect(() => {
        tg.onEvent('mainButtonClicked', sendData);
        return () => {
            tg.offEvent('mainButtonClicked', sendData);
        };
    }, [onSubmit]);

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Change info',
        });
    }, []);

    useEffect(() => {
        if (dirtyFields.name || dirtyFields.surname) {
            tg.MainButton.show();
        } else {
            tg.MainButton.hide();
        }
    }, [dirtyFields.name, dirtyFields.surname]);

    return (
        <form>
            <Stack flex={1} marginBottom={3}>
                <Input
                    label="Name"
                    placeholder="Name"
                    maxLength={NAME_MAX_LENGTH}
                    name="name"
                    errorMsg={errors.name?.message}
                    variant="outlined"
                    register={register}
                    InputLabelProps={{ className: 'inputLabel', shrink: true }}
                />
                <div css={styles.inputWrapperStyles({ surnameInput: true })}>
                    <Input
                        label="Surname"
                        placeholder="Surname"
                        maxLength={SURNAME_MAX_LENGTH}
                        name="surname"
                        variant="outlined"
                        errorMsg={errors.surname?.message}
                        register={register}
                        InputLabelProps={{
                            className: 'inputLabel',
                            shrink: true,
                        }}
                    />
                </div>
            </Stack>
            <ControlledSwitch control={control} name="isAdmin" label="Admin" />
            <Stack flex={1} marginTop={3}>
                <Typography variant="h6" color={colorScheme === 'dark' ? '#fff' : '#000'}>
                    Team
                </Typography>

                <FormGroup>
                    {Object.entries(USER_TEAM).map(([_, value], _index) => (
                        <FormControlLabel
                            sx={{
                                color: colorScheme === 'dark' ? '#fff' : '#000',
                                userSelect: 'none',
                            }}
                            label={value}
                            key={value}
                            control={<Checkbox checked={userTeams?.includes(value)} disabled />}
                        />
                    ))}
                </FormGroup>
            </Stack>
        </form>
    );
}
