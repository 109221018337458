/* eslint-disable jsx-a11y/no-static-element-interactions */

/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs from 'dayjs';
import { MAX_TIME, MIN_TIME } from '../../../../constants';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

export const MINUTES_IN_HOUR = 60;

interface TimeRowProps {
    meetingRoomDateValue?: string;
    isTimeRowClickable: boolean;
    allMeetingRooms?: boolean;
    meetingRoomName: string;
    enableStartTime: string;
    enableEndTime: string;
    onCellClick: (startTime: string, endTime: string, meetingRoomName: string) => void;
}

function EmptyRow({
    meetingRoomDateValue,
    isTimeRowClickable,
    allMeetingRooms,
    meetingRoomName,
    enableStartTime,
    enableEndTime,
    onCellClick,
}: TimeRowProps) {
    const { colorScheme } = useTelegram();

    const nowDate = dayjs().format('YYYY-MM-DD');

    const enableStartTimeDayjs = dayjs(`${nowDate} ${enableStartTime}`);

    const enableEndTimeDayjs = dayjs(`${nowDate} ${enableEndTime}`);

    // @ts-ignore
    const timeDuration = dayjs.duration(enableEndTimeDayjs.diff(enableStartTimeDayjs));

    // Calculating Top

    const top = `calc((100% / 16 * ${enableStartTimeDayjs.get('hour') - 7}) ${
        enableStartTimeDayjs.get('minutes')
            ? `+ (100% / 16 / 60 * ${enableStartTimeDayjs.get('minutes')})`
            : ''
    })`;

    // Calculating height
    const timeFactor = timeDuration.hours() + timeDuration.minutes() / MINUTES_IN_HOUR;

    const height = `calc(100% / 16 * ${timeFactor})`;

    return (
        <div
            onClick={() => onCellClick(enableStartTime, enableEndTime, meetingRoomName)}
            css={styles.enableTimeRow({
                first:
                    enableStartTimeDayjs.get('minutes') === 0 &&
                    enableStartTimeDayjs.get('hour') === MIN_TIME,
                last:
                    enableEndTimeDayjs.get('minutes') === MINUTES_IN_HOUR &&
                    MAX_TIME === enableEndTimeDayjs.get('hour'),
                height,
                top,
                colorScheme,
                allMeetingRooms,
                meetingRoomName,
            })}
        />
    );
}

export default EmptyRow;
