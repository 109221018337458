/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const teamBoxColor = (teamColor: string) => {
    return css({
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px !important',

        '&::before': {
            content: '""',
            display: 'inline-block',
            width: '15px',
            height: '15px',
            backgroundColor: teamColor,
            borderRadius: '3px',
            marginRight: '7px',
        },
    });
};

const headerTextStyles = css({
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#0f0f0f',
    textAlign: 'center',
});

const wrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            padding: '20px 20px 20px 20px',
            height: '100vh',
            background: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
            overflowY: 'scroll',
            overflowX: 'hidden',
        }),
    );
};

const styles = { teamBoxColor, headerTextStyles, wrapperStyles };

export default styles;
