/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const headerTextStyles = css({
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#0f0f0f',
});

const styles = {
    headerTextStyles,
};

export default styles;
