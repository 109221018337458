/** @jsxImportSource @emotion/react */

import styles from './styles';

export interface TeamAssignmentItemProps {
    color: string;
    text: string;
}

export function TeamAssignmentItem({ text, color }: TeamAssignmentItemProps) {
    return <div css={styles.teamBoxColor(color)}>{text}</div>;
}
