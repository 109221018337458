/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material';
import { TeamAssignmentItem } from './TeamAssignmentItem/TeamAssignmentItem';
import { TEAM_ASSIGNMENT_LIST } from '../../../../constants';

export function TeamAssignmentList() {
    return (
        <Box>
            {TEAM_ASSIGNMENT_LIST.map((teamItem) => (
                <TeamAssignmentItem color={teamItem.color} text={teamItem.text} />
            ))}
        </Box>
    );
}
