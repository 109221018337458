/** @jsxImportSource @emotion/react */
import { Box, CircularProgress, Drawer, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MUIThemeProvider } from '../../../common/muiThemeProvider/MUIThemeProvider';
import { DeskManagementForm } from './DeskManagementForm/DeskManagementForm';
import { officeSpaceApi } from '../../../../api/officeSpaceApi';
import styles from './styles';

type DeskManagementDrawerProps = {
    value: string;
    open: boolean;
    onClose: () => void;
};

export function DeskManagementDrawer({ open, onClose, value }: DeskManagementDrawerProps) {
    const { isError, isLoading, data } = officeSpaceApi.useOneSpaceByNameQuery(
        {
            name: value,
            include: ['bookings'],
        },
        { skip: !value || !open },
    );

    const handleGoBackClick = () => {
        onClose();
    };

    return (
        <MUIThemeProvider>
            <Drawer
                sx={{ width: '100vw', overflow: 'hidden' }}
                PaperProps={{
                    sx: { width: '100%', height: '100%', display: 'flex', flexDirection: 'column' },
                }}
                open={open}
                onClose={onClose}
            >
                <Box p={3} height="100%" display="flex" flexDirection="column" gap={3}>
                    {data && (
                        <>
                            <Stack direction="row" alignItems="center" gap={2}>
                                <IconButton onClick={handleGoBackClick}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <h3 css={styles.headerTextStyles}>Desk {value}</h3>
                            </Stack>
                            <DeskManagementForm
                                data={data.data[0]}
                                handleGoBackClick={handleGoBackClick}
                            />
                        </>
                    )}
                    {isLoading && (
                        <Stack
                            height="100vh"
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Stack>
                    )}
                    {isError && (
                        <Stack
                            height="100vh"
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography>Error occured. Try again later.</Typography>
                        </Stack>
                    )}
                </Box>
            </Drawer>
        </MUIThemeProvider>
    );
}
