/** @jsxImportSource @emotion/react */
import React, { useEffect, useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams /* useNavigate */ } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useTelegram } from '../../../hooks/useTelegram';
import {
    useEditMeetingRoomBookingMutation,
    useLazyGetAllMeetingRoomsUsersQuery,
    useRemoveMeetingRoomBookingMutation,
} from '../../../api/rootApi';
import {
    ADMIN_ROUTES,
    APP_ROUTES,
    MIN_BOOKING_MINUTES_VALUE,
    REPEAT_VALUE,
} from '../../../constants';
import { ConfirmModal } from './confirmModal';
import StartTimePicker from './startTimePicker';
import EndTimePicker from './endTimePicker';
import BookMeetingRoomHeader from './bookMeetingRoomHeader';
import {
    MeetingRoomUser,
    setAllMeetingRoomUsers,
    setEditedUserMeetingRoom,
} from '../../../store/reducers/meetingRooms';
import { BookInfoModal } from './bookInfoModal';
import { isTimeDisabled } from '../../../utils/isTimeDisabled';
import EditButtons from './editButtons';
import { DeleteInfoModal } from './deleteInfoModal';
import { useEnhancedNavigate } from '../../../hooks/useEnhancedNavigate';
import RepeatButtons from './repeatButtons';
import { EditRepeatBookingModal, EDIT_RADIO_VALUES } from './editRepeatedBookingModal';
import { REMOVE_RADIO_VALUES, RemoveRepeatBookingModal } from './removeRepeatBookingModal';

import styles from './styles';
import { UsersSelect } from '../../common/UsersSelect/UsersSelect';
import { meetingRoomBookingApi } from '../../../api/meetingRoomBookingApi';
import { MeetingRoomBooking } from '../../../types';
import { MEETING_ROOM_BOOKING_LIST_INCLUDE } from '../../../api/types';
import { FullPageLoader } from '../../common/FullPageLoader/FullPageLoader';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

const TIME_ERROR_MESSAGE = 'This time is unavailable';
const REPEATED_TIME_ERROR_MESSAGE = 'This time is unavailable for the selected period';

function BookMeetingRoomLayout() {
    const [bookingUserId, setBookingUserId] = useState<string | null>(null);
    const [startTimeValue, setStartTimeValue] = useState<Dayjs | null | undefined>();
    const [endTimeValue, setEndTimeValue] = useState<Dayjs | null | undefined>();
    const [calendarValue, setCalendarValue] = useState<Dayjs | null | undefined>();
    const [chosenDateState, setChosenDateState] = useState<string | undefined>(undefined);
    const [startTimeError, setStarTimeError] = useState('');
    const [endTimeError, setEndTimeError] = useState('');
    const [checked, setChecked] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [userHaveBookingsModalActive, setUserHaveBookingsModalActive] = useState(false);
    const [bookedMeetingRoom, setBookedMeetingRoom] = useState<MeetingRoomUser | null>(null);
    const [allDayBookedMeetingRoom, setAllDayBookedMeetingRoom] = useState<MeetingRoomUser | null>(
        null,
    );
    const [editModeActive, setEditModeActive] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const [checkedRepeatMode, setCheckedRepeatMode] = useState(false);
    const [repeatRadioValue, setRepeatRadioValue] = useState('Never');
    const [repeatEndDate, setRepeatEndDate] = useState<Dayjs | null>(null);
    const [repeatValue, setRepeatValue] = useState<REPEAT_VALUE>(REPEAT_VALUE.DAY);
    const [editRepeatBookingModalActive, setEditRepeatBookingModalActive] = useState(false);
    const [editRadioValue, setEditRadioValue] = useState(EDIT_RADIO_VALUES.CURRENT);
    const [removeRadioValue, setRemoveRadioValue] = useState<REMOVE_RADIO_VALUES>(
        REMOVE_RADIO_VALUES.CURRENT,
    );
    const [removeRepeatBookingModalActive, setRemoveRepeatBookingModalActive] = useState(false);

    const [bookingToEdit, setBookingToEdit] = useState<
        (MeetingRoomBooking & { meetingRoomName: string }) | null
    >(null);

    const [getMeetingRoomBookings] = meetingRoomBookingApi.useLazyListQuery();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [queryParams] = useSearchParams();
    const location = useLocation();

    const editedMeetingRoomBookingId = queryParams.get('editedMeetingRoomBookingId') ?? undefined;
    const chosenStartTime = queryParams.get('bookingStartTime') ?? undefined;
    const chosenEndTime = queryParams.get('bookingEndTime') ?? undefined;
    const bookingDate = queryParams.get('bookingDate') ?? undefined;
    const meetingRoomName = queryParams.get('meetingRoomName') ?? undefined;
    const isMyBookings = Boolean(queryParams.get('isMyBookings'));
    const isAllMeetingRooms = Boolean(queryParams.get('isAllMeetingRooms'));
    const isAllDayChecked = Boolean(queryParams.get('isAllDay'));

    const prevPagePathname = location?.state?.prevPagePathname as string;

    const { scrollNavigate } = useEnhancedNavigate();

    const todayDate = dayjs().format('YYYY-MM-DD');

    const dispatch = useDispatch();

    const { tg, queryId, tgUserId, colorScheme } = useTelegram();

    const [getAllMeetingRoomUsers, { isFetching: isFetchingGetAllMeetingRoomUsers }] =
        useLazyGetAllMeetingRoomsUsersQuery();

    const [bookMeetingRoom] = meetingRoomBookingApi.useCreateMutation();

    const [removeMeetingRoomBooking] = useRemoveMeetingRoomBookingMutation();

    const [editMeetingRoomBooking, { isLoading: isEditMeetingRoomBookingLoading }] =
        useEditMeetingRoomBookingMutation();

    const handleUsersSelectChange = (option: { label: string; value: string } | null) => {
        setBookingUserId(option?.value ?? null);
    };

    const fetchMeetingRoomBookings = useCallback(async () => {
        if (editedMeetingRoomBookingId) {
            setIsLoading(true);
            await getMeetingRoomBookings({
                filters: { id: [editedMeetingRoomBookingId] },
                include: [MEETING_ROOM_BOOKING_LIST_INCLUDE.MEETING_ROOMS],
            })
                .unwrap()
                .then((response) => {
                    const booking = response.data[0];

                    setStartTimeValue(dayjs(`${todayDate} ${booking.startTime}`));
                    setEndTimeValue(dayjs(`${todayDate} ${booking.endTime}`));
                    setCheckedRepeatMode(Boolean(booking.isRepeated));
                    setCalendarValue(dayjs(booking.date));

                    const radioValue = booking.isRepeatAlways ? 'Never' : 'On';
                    setRepeatRadioValue(radioValue);

                    if (radioValue === 'On') {
                        setRepeatEndDate(dayjs(booking.repeatEndDate));
                    } else {
                        setRepeatEndDate(dayjs());
                    }
                    if (booking.repeatValue) {
                        setRepeatValue(booking.repeatValue as REPEAT_VALUE);
                    }

                    setBookingToEdit({
                        ...booking,
                        meetingRoomName: booking.meetingRoom?.name as string,
                    });
                });

            setIsLoading(false);
        }
    }, [editedMeetingRoomBookingId]);

    const handleSaveClick = () => {
        if (editedMeetingRoomBookingId && startTimeValue && endTimeValue) {
            if (bookingToEdit?.isRepeated) {
                setEditRepeatBookingModalActive(true);
            } else {
                onEditHandler();
            }
        }
    };

    useEffect(() => {
        fetchMeetingRoomBookings();
    }, [fetchMeetingRoomBookings]);

    useEffect(() => {
        if (!editedMeetingRoomBookingId) {
            setStartTimeValue(dayjs(`${todayDate} ${chosenStartTime}`));
            setEndTimeValue(dayjs(`${todayDate} ${chosenEndTime}`));
            setChecked(isAllDayChecked);
        }
    }, []);

    useEffect(() => {
        if (repeatRadioValue === 'Never' && !editedMeetingRoomBookingId) {
            setRepeatEndDate(null);
        } else if (!editedMeetingRoomBookingId) {
            setRepeatEndDate(dayjs(chosenDateState));
        }
    }, [repeatRadioValue]);

    useEffect(() => {
        if (chosenDateState) {
            dispatch(setAllMeetingRoomUsers({ newMeetingRoomUsers: [] }));
            if (editedMeetingRoomBookingId) {
                let editedRepeatEndDate;
                let getRepeatValue;
                if (bookingToEdit?.isRepeatAlways || repeatRadioValue === 'Never') {
                    editedRepeatEndDate = undefined;
                } else if (bookingToEdit?.isRepeated && checkedRepeatMode) {
                    editedRepeatEndDate = dayjs(bookingToEdit?.repeatEndDate).format('YYYY-MM-DD');
                } else if (!bookingToEdit?.isRepeated) {
                    editedRepeatEndDate = dayjs(bookingToEdit?.date).format('YYYY-MM-DD');
                }
                if (repeatValue === REPEAT_VALUE.WEEK || repeatValue === REPEAT_VALUE.MONTH) {
                    getRepeatValue = repeatValue;
                }
                getAllMeetingRoomUsers({
                    date: chosenDateState,
                    endDate: editedRepeatEndDate,
                    repeatValue: getRepeatValue,
                });
            } else {
                let getRepeatValue;
                if (repeatValue === REPEAT_VALUE.WEEK || repeatValue === REPEAT_VALUE.MONTH) {
                    getRepeatValue = repeatValue;
                }
                getAllMeetingRoomUsers({
                    date: chosenDateState,
                    repeatValue: getRepeatValue,
                });
            }
        }
    }, [
        chosenDateState,
        checkedRepeatMode,
        repeatEndDate,
        repeatRadioValue,
        repeatValue,
        editModeActive,
    ]);

    const meetingRoomUsers = useAppSelector(
        (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
    ).filter(
        (m) =>
            m.userMeetingRoomInfo.id !== editedMeetingRoomBookingId &&
            `MR${meetingRoomName?.split(' ')[2]}` === m.userMeetingRoomInfo.spaceName,
    );

    const allMeetingRoomUsers = useAppSelector(
        (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
    ).filter((m) => m.userMeetingRoomInfo.id !== editedMeetingRoomBookingId);

    useEffect(() => {
        if (bookingDate) {
            setCalendarValue(dayjs(bookingDate));
            setChosenDateState(bookingDate);
        }
    }, [bookingDate]);

    useEffect(() => {
        if (startTimeValue && endTimeValue) {
            const myAllBookings = allMeetingRoomUsers.filter((u) => u.telegramUserId === tgUserId);

            const key = 'startTime';

            const uniqueAllBookings = [
                ...new Map(
                    myAllBookings.map((item) => [item.userMeetingRoomInfo[key], item]),
                ).values(),
            ];

            const uniqueMeetingRoomBookings = [
                ...new Map(
                    meetingRoomUsers.map((item) => [item.userMeetingRoomInfo[key], item]),
                ).values(),
            ];

            const isStartTimeDisabled = isTimeDisabled(
                startTimeValue.format('HH:mm'),
                meetingRoomUsers,
                false,
            );

            const isStartTimeDisabledInAllMeetingRooms = isTimeDisabled(
                startTimeValue.format('HH:mm'),
                myAllBookings,
                false,
            );

            if (isStartTimeDisabled || isStartTimeDisabledInAllMeetingRooms) {
                if (checkedRepeatMode) {
                    setStarTimeError(REPEATED_TIME_ERROR_MESSAGE);
                } else {
                    setStarTimeError(TIME_ERROR_MESSAGE);
                }
            } else {
                setStarTimeError('');
            }

            const isMoreThan15Minutes =
                endTimeValue.diff(startTimeValue, 'minutes') >= MIN_BOOKING_MINUTES_VALUE;

            if (!isMoreThan15Minutes) {
                setEndTimeError('Booking time should be not less than 15 minutes');
            }

            const isEndTimeDisabled = isTimeDisabled(
                endTimeValue.format('HH:mm'),
                uniqueMeetingRoomBookings,
                true,
                startTimeValue,
            );

            const isEndTimeDisabledInAllMeetingRooms = isTimeDisabled(
                endTimeValue.format('HH:mm'),
                uniqueAllBookings,
                true,
                startTimeValue,
            );

            if ((isEndTimeDisabled || isEndTimeDisabledInAllMeetingRooms) && isMoreThan15Minutes) {
                if (checkedRepeatMode) {
                    setEndTimeError(REPEATED_TIME_ERROR_MESSAGE);
                } else {
                    setEndTimeError(TIME_ERROR_MESSAGE);
                }
            }

            if (!isEndTimeDisabled && !isEndTimeDisabledInAllMeetingRooms && isMoreThan15Minutes) {
                setEndTimeError('');
            }
        }
    }, [
        startTimeValue,
        endTimeValue,
        chosenDateState,
        editedMeetingRoomBookingId,
        allMeetingRoomUsers,
        meetingRoomUsers,
    ]);

    useEffect(() => {
        const bookedOtherMeetingRoom = allMeetingRoomUsers.find((u) => {
            const bookedStartTimeDate = dayjs(`${todayDate} ${u.userMeetingRoomInfo.startTime}`);
            const bookedEndTimeDate = dayjs(`${todayDate} ${u.userMeetingRoomInfo.endTime}`);

            // @ts-ignore
            const isStartTimeBetween = startTimeValue?.isBetween(
                bookedStartTimeDate,
                bookedEndTimeDate,
                '[]',
            );
            // @ts-ignore
            const isEndTimeBetween = endTimeValue?.isBetween(
                bookedStartTimeDate,
                bookedEndTimeDate,
                '[]',
            );

            const isEndTimeAfterBookedEndTime = endTimeValue?.isAfter(bookedEndTimeDate);

            const isStartTimeBeforeBookedTime = startTimeValue?.isBefore(bookedStartTimeDate);

            if (
                u.userMeetingRoomInfo.roomId &&
                u.telegramUserId === tgUserId &&
                ((startTimeValue && endTimeValue && (isEndTimeBetween || isStartTimeBetween)) ||
                    startTimeValue?.format('HH:mm:ss') === u.userMeetingRoomInfo.startTime ||
                    endTimeValue?.format('HH:mm:ss') === u.userMeetingRoomInfo.endTime ||
                    (startTimeValue &&
                        endTimeValue &&
                        isStartTimeBeforeBookedTime &&
                        isEndTimeAfterBookedEndTime))
            ) {
                return u;
            }
            return undefined;
        });

        if (bookedOtherMeetingRoom && !startTimeError && !endTimeError) {
            setBookedMeetingRoom(bookedOtherMeetingRoom);
            setUserHaveBookingsModalActive(true);
        } else {
            setBookedMeetingRoom(null);
            setUserHaveBookingsModalActive(false);
        }
    }, [
        startTimeValue,
        endTimeValue,
        chosenDateState,
        allMeetingRoomUsers,
        startTimeError,
        endTimeError,
    ]);

    /* const userEmail = useAppSelector((store) => store.userReducer.user.email); */

    const onSendData = useCallback(async () => {
        if (
            queryId &&
            startTimeValue &&
            endTimeValue &&
            tgUserId &&
            !bookedMeetingRoom &&
            chosenDateState &&
            meetingRoomName
        ) {
            const startTimeString = startTimeValue.format('HH:mm:ss');
            const endTimeString = endTimeValue.format('HH:mm:ss');

            const isRepeatAlways = repeatRadioValue === 'Never';

            bookMeetingRoom({
                queryId: queryId || '',
                date: chosenDateState,
                telegramUserId: bookingUserId ? Number(bookingUserId) : tgUserId!,
                startTime: startTimeString,
                endTime: endTimeString,
                meetingRoomName,
                allDay: checked,
                isRepeated: checkedRepeatMode,
                repeatValue: checkedRepeatMode ? repeatValue : undefined,
                repeatEndDate: checkedRepeatMode ? repeatEndDate?.format('YYYY-MM-DD') : undefined,
                permanent: checkedRepeatMode ? isRepeatAlways : false,
            });
        }
    }, [
        startTimeValue,
        endTimeValue,
        chosenDateState,
        repeatValue,
        checkedRepeatMode,
        repeatEndDate,
        repeatRadioValue,
        bookingUserId,
    ]);

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData);
        return () => {
            tg.offEvent('mainButtonClicked', onSendData);
        };
    }, [onSendData]);

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Confirm',
        });
    }, []);

    useEffect(() => {
        if (
            !startTimeValue ||
            editedMeetingRoomBookingId ||
            !endTimeValue ||
            endTimeError ||
            startTimeError ||
            userHaveBookingsModalActive ||
            bookedMeetingRoom ||
            modalActive ||
            isEditMeetingRoomBookingLoading
        ) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [
        startTimeValue,
        endTimeValue,
        endTimeError,
        startTimeError,
        modalActive,
        userHaveBookingsModalActive,
        bookedMeetingRoom,
        editedMeetingRoomBookingId,
        isEditMeetingRoomBookingLoading,
    ]);

    const isAuthorHaveBookings = meetingRoomUsers.some((m) => m.telegramUserId === tgUserId);

    const isOtherUsersHaveBooking = meetingRoomUsers.some((m) => m.telegramUserId !== tgUserId);

    const onModalCancel = () => {
        setModalActive(false);
    };

    const onModalConfirm = () => {
        const minTime = dayjs(`${todayDate} 07:00:00`);
        const maxTime = dayjs(`${todayDate} 23:00:00`);

        setStartTimeValue(minTime);
        setEndTimeValue(maxTime);

        setModalActive(false);
    };

    const chosenDateString = dayjs(chosenDateState).format('DD.MM.YYYY');

    const onCloseDeleteInfoModalHandler = () => {
        if (editedMeetingRoomBookingId && checkedRepeatMode) {
            setDeleteModalActive(false);
        } else {
            dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: null }));

            setDeleteModalActive(false);

            if (isAllMeetingRooms) {
                scrollNavigate({
                    top: 0,
                    left: 0,
                    path: `${APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}?date=${chosenDateState}`,
                    replace: true,
                    behavior: 'smooth',
                });
            } else {
                scrollNavigate({
                    top: 0,
                    left: 0,
                    path: APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE,
                    replace: true,
                    behavior: 'smooth',
                    queryParams: {
                        meetingRoomId: bookingToEdit?.spaceId as string,
                        meetingRoomName: bookingToEdit?.meetingRoomName as string,
                        date: calendarValue?.format('YYYY-MM-DD') as string,
                    },
                });
            }
        }
    };

    const onEditHandler = async () => {
        if (startTimeValue && endTimeValue && editedMeetingRoomBookingId) {
            const isRepeatAlways = repeatRadioValue === 'Never';

            const repeatEndDateValue = isRepeatAlways ? null : repeatEndDate?.format('YYYY-MM-DD');

            const result = await editMeetingRoomBooking({
                id: editedMeetingRoomBookingId,
                startTime: startTimeValue.format('HH:mm:ss'),
                endTime: endTimeValue.format('HH:mm:ss'),
                allDay: checked,
                isRepeated: checkedRepeatMode,
                repeatValue: checkedRepeatMode ? repeatValue : null,
                repeatEndDate: checkedRepeatMode ? repeatEndDateValue : null,
                isRepeatAlways: checkedRepeatMode ? isRepeatAlways : false,
                isEditCurrent:
                    editRadioValue === EDIT_RADIO_VALUES.CURRENT &&
                    checkedRepeatMode &&
                    editRepeatBookingModalActive,
                editDate: calendarValue?.format('YYYY-MM-DD'),
                /*    userEmail, */
            }).unwrap();

            if (result) {
                dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: null }));

                setEditModeActive(false);

                setEditRepeatBookingModalActive(false);

                scrollNavigate({
                    top: 0,
                    left: 0,
                    path: APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE,
                    replace: true,
                    behavior: 'smooth',
                    queryParams: {
                        meetingRoomName: bookingToEdit?.meetingRoomName!,
                        meetingRoomId: bookingToEdit?.spaceId as string,
                        date: calendarValue?.format('YYYY-MM-DD') as string,
                    },
                });
            }
        }
    };

    const deleteRepeatedBooking = async () => {
        if (editedMeetingRoomBookingId && chosenDateState) {
            const result = await removeMeetingRoomBooking({
                id: editedMeetingRoomBookingId,
                removeDate: chosenDateState,
                removeUpcoming: removeRadioValue === REMOVE_RADIO_VALUES.UPCOMING,
                removeAll: removeRadioValue === REMOVE_RADIO_VALUES.ALL,
                removeCurrent: removeRadioValue === REMOVE_RADIO_VALUES.CURRENT,
            }).unwrap();

            if (result) {
                dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: null }));

                setDeleteModalActive(false);

                if (isAllMeetingRooms) {
                    scrollNavigate({
                        top: 0,
                        left: 0,
                        // eslint-disable-next-line max-len
                        path: `${APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}?date=${chosenDateState}`,
                        replace: true,
                        behavior: 'smooth',
                    });
                } else {
                    scrollNavigate({
                        top: 0,
                        left: 0,
                        path: `${
                            APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE
                            // &meetingRoomId=${meetingRoomId}
                        }?date=${bookingDate}&meetingRoomName=${meetingRoomName}${
                            isMyBookings ? `&isMyBookings=${isMyBookings}` : ''
                        }`,
                        replace: true,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };

    if (isLoading) return <FullPageLoader />;

    // TODO: Refactor to support react-hook-form
    return (
        <div css={styles.wrapperStyles({ colorScheme })}>
            {isFetchingGetAllMeetingRoomUsers ? (
                <Grid item padding={7} display="flex" justifyContent="center" xs={12}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <BookMeetingRoomHeader
                        setModalActive={setModalActive}
                        setStartTimeValue={setStartTimeValue}
                        setEndTimeValue={setEndTimeValue}
                        isMyBookings={isMyBookings}
                        setChecked={setChecked}
                        checked={checked}
                        chosenDateState={chosenDateState}
                        isOtherUsersHaveBooking={isOtherUsersHaveBooking}
                        isAuthorHaveBookings={isAuthorHaveBookings}
                        setChosenDateState={setChosenDateState}
                        calendarValue={calendarValue}
                        setCalendarValue={setCalendarValue}
                        allDayBookedMeetingRoom={allDayBookedMeetingRoom}
                        setAllDayBookedMeetingRoom={setAllDayBookedMeetingRoom}
                        editModeActive={editModeActive}
                        meetingRoomName={meetingRoomName}
                        // meetingRoomId={meetingRoomId}
                        bookingDate={bookingDate}
                        isAllMeetingRooms={isAllMeetingRooms}
                        chosenStartTime={chosenStartTime}
                        chosenEndTime={chosenEndTime}
                        prevPagePathname={prevPagePathname}
                    />
                    <h3 css={styles.titleStyles({ colorScheme })}>Set meeting room time</h3>
                    <StartTimePicker
                        startTimeValue={startTimeValue}
                        setStartTimeValue={setStartTimeValue}
                        chosenDateState={chosenDateState}
                        endTimeValue={endTimeValue}
                        setError={setStarTimeError}
                        startTimeError={startTimeError}
                        editModeActive={editModeActive}
                        checked={checked}
                        setChecked={setChecked}
                        meetingRoomUsers={meetingRoomUsers}
                        disabled={Boolean(!editModeActive && !!editedMeetingRoomBookingId)}
                    />
                    <EndTimePicker
                        startTimeValue={startTimeValue}
                        endTimeValue={endTimeValue}
                        setEndTimeValue={setEndTimeValue}
                        chosenDateState={chosenDateState}
                        setError={setEndTimeError}
                        endTimeError={endTimeError}
                        editModeActive={editModeActive}
                        checked={checked}
                        setChecked={setChecked}
                        meetingRoomUsers={meetingRoomUsers}
                        disabled={Boolean(!editModeActive && !!editedMeetingRoomBookingId)}
                    />
                    {prevPagePathname === ADMIN_ROUTES.MANAGE_MR_BOOKINGS && (
                        <div style={{ marginTop: 20 }}>
                            <h3 css={styles.titleStyles({ colorScheme })}>Select user</h3>
                            <UsersSelect label="User" onChange={handleUsersSelectChange} />
                        </div>
                    )}
                    <RepeatButtons
                        editModeActive={editModeActive}
                        checkedRepeatMode={checkedRepeatMode}
                        setCheckedRepeatMode={setCheckedRepeatMode}
                        repeatRadioValue={repeatRadioValue}
                        setRepeatRadioValue={setRepeatRadioValue}
                        repeatEndDate={repeatEndDate}
                        setRepeatEndDate={setRepeatEndDate}
                        repeatValue={repeatValue}
                        setRepeatValue={setRepeatValue}
                        chosenDateState={chosenDateState}
                    />
                    {editedMeetingRoomBookingId && (
                        <EditButtons
                            isRepeated={false}
                            isEditMeetingRoomBookingLoading={isEditMeetingRoomBookingLoading}
                            onEditHandler={onEditHandler}
                            startTimeValue={startTimeValue}
                            endTimeValue={endTimeValue}
                            setEditModeActive={setEditModeActive}
                            setDeleteModalActive={setDeleteModalActive}
                            editModeActive={editModeActive}
                            startTimeError={startTimeError}
                            endTimeError={endTimeError}
                            setEditRepeatBookingModalActive={setEditRepeatBookingModalActive}
                            isRepeatAlways={repeatRadioValue === 'Never' && checkedRepeatMode}
                            repeatEndDate={repeatEndDate?.format('YYYY-MM-DD')}
                            repeatValue={repeatValue}
                            setRemoveRepeatBookingModalActive={setRemoveRepeatBookingModalActive}
                            onEditClick={() => {}}
                            onSaveClick={handleSaveClick}
                        />
                    )}
                    <ConfirmModal
                        onCancel={onModalCancel}
                        open={modalActive}
                        setOpen={setModalActive}
                        onConfirm={onModalConfirm}
                    />
                    <DeleteInfoModal
                        open={deleteModalActive}
                        onClose={onCloseDeleteInfoModalHandler}
                        isRepeated={checkedRepeatMode}
                        onDeleteWorkPlaceHandler={deleteRepeatedBooking}
                    />
                    <RemoveRepeatBookingModal
                        open={removeRepeatBookingModalActive}
                        onClose={() => setRemoveRepeatBookingModalActive(false)}
                        onDeleteWorkPlaceHandler={deleteRepeatedBooking}
                        removeRadioValue={removeRadioValue}
                        setRemoveRadioValue={setRemoveRadioValue}
                    />
                    {userHaveBookingsModalActive && bookedMeetingRoom && meetingRoomName && (
                        <BookInfoModal
                            setOpen={setUserHaveBookingsModalActive}
                            open={userHaveBookingsModalActive}
                            meetingRoomName={meetingRoomName}
                            bookedMeetingRoomName={bookedMeetingRoom.userMeetingRoomInfo.spaceName}
                            chosenDate={chosenDateString}
                            setChecked={setChecked}
                        />
                    )}
                    <EditRepeatBookingModal
                        open={editRepeatBookingModalActive}
                        onClose={() => setEditRepeatBookingModalActive(false)}
                        editRadioValue={editRadioValue}
                        setEditRadioValue={setEditRadioValue}
                        onEditBookingHandler={onEditHandler}
                    />
                </>
            )}
        </div>
    );
}

export default BookMeetingRoomLayout;
