import React from 'react';

function BackDateIcon() {
    return (
        <svg
            width="6"
            height="12"
            viewBox="0 0 6 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                // eslint-disable-next-line max-len
                d="M5.48096 11L1.3648 6.88384C0.878684 6.39773 0.878684 5.60227 1.3648 5.11616L5.48096 1"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default BackDateIcon;
