import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeskManagementFormSchema, deskManagementFormSchema } from './deskManagementForm.schema';
import { USER_TEAM } from '../../../../../constants';
import { ControlledSwitch } from '../../../../common/ControlledSwitch/ControlledSwitch';
import { OfficeSpaceListResponse } from '../../../../../api/types';
import { officeSpaceApi } from '../../../../../api/officeSpaceApi';
import { useSnackbarContext } from '../../../../common/SnackbarProvider/SnackbarProvider';
import { ControlledCheckboxGroup } from '../../../../common/ControlledCheckboxGroup';

type DeskManagementFormProps = {
    data: {
        id: string;
        name: string;
        bookingTeams: USER_TEAM[] | null;
        users?: OfficeSpaceListResponse['data'][number]['users'];
    };
    handleGoBackClick: () => void;
};

export function DeskManagementForm({ data, handleGoBackClick }: DeskManagementFormProps) {
    const {
        formState: { dirtyFields },
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
    } = useForm<DeskManagementFormSchema>({
        resolver: zodResolver(deskManagementFormSchema),
        defaultValues: {
            bookingTeams: data?.bookingTeams,
        },
    });

    const [updateOfficeSpace, { isLoading }] = officeSpaceApi.useUpdateOfficeSpaceMutation();

    const [isDeskBooked, setIsDeskBooked] = useState(false);

    const { showSnackbar } = useSnackbarContext();

    const onSubmit: SubmitHandler<DeskManagementFormSchema> = async (formData) => {
        await updateOfficeSpace({ id: data.id, bookingTeams: formData.bookingTeams })
            .unwrap()
            .then(() => {
                showSnackbar('Office space successfully updated', 'success');
                handleGoBackClick();
            })
            .catch((err: { status: number }) => {
                if (err.status === 409) {
                    showSnackbar('The desk has active bookings. Please remove them first', 'error');
                    setIsDeskBooked(true);
                } else {
                    showSnackbar('Error occured while updating the office space', 'error');
                }
            });
    };

    const sendData = handleSubmit(onSubmit);

    useEffect(() => {
        reset({
            bookingTeams: data?.bookingTeams,
        });
    }, [data]);

    return (
        <form onSubmit={sendData} style={{ flex: '1' }}>
            <Stack direction="column" height="100%">
                <Stack flex={1} gap={3}>
                    <ControlledSwitch
                        name="outOfService"
                        control={control}
                        disabled
                        label="Out Of Service"
                        addColourfulBox
                    />
                    <Typography>Available for:</Typography>
                    <ControlledCheckboxGroup
                        options={checkboxOptions}
                        name="bookingTeams"
                        control={control}
                        setValue={setValue}
                        watch={watch}
                    />
                    {isDeskBooked && (
                        <Alert severity="warning">
                            The desk has active bookings. Please remove all bookings first.
                        </Alert>
                    )}
                </Stack>
                <Stack>
                    <LoadingButton
                        loading={isLoading}
                        disabled={!Object.keys(dirtyFields).length}
                        type="submit"
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#40a7e3',
                            color: '#ffffff',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                            ':hover': {
                                backgroundColor: '#40a7e3',
                            },
                        }}
                    >
                        Update desk {data.name}
                    </LoadingButton>
                </Stack>
            </Stack>
        </form>
    );
}

const checkboxOptions = [
    { value: USER_TEAM.TECHNICAL, label: `${USER_TEAM.TECHNICAL} team`, color: '#a4c1ec' },
    {
        value: USER_TEAM.ADMINISTRATIVE,
        label: `${USER_TEAM.ADMINISTRATIVE} team`,
        color: '#ffe8a4',
    },
];
