import { Route, Routes } from 'react-router-dom';
import BookMeetingRoomLayout from '../components/layouts/bookMeetingRoomLayout';
import BookPeriodLayout from '../components/layouts/bookPeriodLayout';
import MeetingRoomTimetable from '../components/layouts/meetingRoomTimetable';
import MyBookingsLayout from '../components/layouts/myBookingsLayout';
import OfficeMap from '../components/layouts/officeMapLayout';
import RegistrationLayout from '../components/layouts/registrationLayout';
import UserProfile from '../components/layouts/userProfile/UserProfile';
import { ADMIN_ROUTES, APP_ROUTES } from '../constants';
/* import SignInLayout from '../components/layouts/signIn'; */
import { PrivateRoute } from '../utils/privateRoute';
import ConfirmGoogleLoginLayout from '../components/layouts/confirmGoogleLoginLayout';
import { UsersListLayout } from '../components/layouts/usersListLayout';
import { UserManagementProfileLayout } from '../components/layouts/userManagementProfile';
import { MUIThemeProvider } from '../components/common/muiThemeProvider/MUIThemeProvider';
import { DeskManagementLayout } from '../components/layouts/DeskManagementLayout';
import { ManageDeskBookingsLayout } from '../components/layouts/ManageDeskBookings';
import { AllMeetingRoomsScheduleLayout } from '../components/layouts/ManageMeetingRoomBookings';

export function Router() {
    return (
        <Routes>
            <Route path={APP_ROUTES.HOME_PAGE} element={<PrivateRoute to={<OfficeMap />} />} />
            <Route path={APP_ROUTES.REGISTRATION_PAGE} element={<RegistrationLayout />} />
            <Route
                path={APP_ROUTES.USER_PROFILE_PAGE}
                element={<PrivateRoute to={<UserProfile />} />}
            />
            <Route
                path={APP_ROUTES.BOOK_PERIOD_PAGE}
                element={<PrivateRoute to={<BookPeriodLayout />} />}
            />
            <Route
                path={APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE}
                element={<PrivateRoute to={<MeetingRoomTimetable />} />}
            />
            <Route
                path={APP_ROUTES.BOOK_MEETING_ROOM}
                element={<PrivateRoute to={<BookMeetingRoomLayout />} />}
            />
            <Route
                path={APP_ROUTES.MY_BOOKINGS_ROUTE}
                element={<PrivateRoute to={<MyBookingsLayout />} />}
            />
            <Route path={APP_ROUTES.CONFIRM_GOOGLE_LOGIN} element={<ConfirmGoogleLoginLayout />} />
            {/*  <Route path={APP_ROUTES.SIGN_IN} element={<SignInLayout />} /> */}
            <Route
                path={APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}
                element={<PrivateRoute to={<AllMeetingRoomsScheduleLayout />} />}
            />
            <Route
                path={ADMIN_ROUTES.USERS_LIST}
                element={
                    <MUIThemeProvider>
                        <UsersListLayout />
                    </MUIThemeProvider>
                }
            />
            <Route
                path={ADMIN_ROUTES.USER_MANAGEMENT_PROFILE}
                element={
                    <MUIThemeProvider>
                        <UserManagementProfileLayout />
                    </MUIThemeProvider>
                }
            />
            <Route
                path={ADMIN_ROUTES.UPDATE_DESK_SETTINGS}
                element={
                    <MUIThemeProvider>
                        <DeskManagementLayout />
                    </MUIThemeProvider>
                }
            />
            <Route
                path={ADMIN_ROUTES.MANAGE_DESK_BOOKINGS}
                element={
                    <MUIThemeProvider>
                        <ManageDeskBookingsLayout />
                    </MUIThemeProvider>
                }
            />
            <Route
                path={ADMIN_ROUTES.MANAGE_MR_BOOKINGS}
                element={
                    <MUIThemeProvider>
                        <AllMeetingRoomsScheduleLayout adminPage />
                    </MUIThemeProvider>
                }
            />
        </Routes>
    );
}
