import { FormControlLabel, Switch } from '@mui/material';
import { type FieldValues, type Control, type Path, Controller } from 'react-hook-form';
import './styles.css';

type ControlledSwitchProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    label?: string;
    disabled?: boolean;
    addColourfulBox?: boolean;
};

export function ControlledSwitch<T extends FieldValues>({
    control,
    name,
    label,
    disabled,
    addColourfulBox,
}: ControlledSwitchProps<T>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControlLabel
                    label={label}
                    disabled={disabled}
                    className={addColourfulBox ? 'switchStyle' : ''}
                    control={<Switch disabled {...field} checked={field.value} />}
                />
            )}
        />
    );
}
