import dayjs from 'dayjs';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

const weekOfYear = require('dayjs/plugin/weekOfYear');

dayjs.extend(weekOfYear);

const weekday = require('dayjs/plugin/weekday');

dayjs.extend(weekday);

type Props = {
    date: string;
    startTime: string;
    endTime: string;
    meetingRoomName: string;
    allMeetingRooms?: boolean;
};

export const generateWeekViewCoordinates = (props: Props) => {
    const start = dayjs(`${props.date} ${props.startTime}`);

    const end = dayjs(`${props.date} ${props.endTime}`);

    // @ts-ignore
    const timeDuration = dayjs.duration(end.diff(start));

    const startDateTime = dayjs(`${props.date} ${props.startTime}`).format('HH:mm:ss');

    const startDateHour = Number(startDateTime.split(':')[0]);

    const startDateMinute = Number(startDateTime.split(':')[1]);

    // Calculating Top

    const top = `calc((100% / 16 * ${startDateHour - 7}) ${
        startDateMinute ? `+ (100% / 16 / 60 * ${startDateMinute})` : ''
    })`;

    // Calculating Left
    let left = '50px';

    if (props.allMeetingRooms && props.meetingRoomName === 'MR1') {
        left = '50px';
    } else if (props.allMeetingRooms && props.meetingRoomName === 'MR2') {
        left = 'calc(((100% - 50px) / 3) + 50px)';
    } else if (props.allMeetingRooms && props.meetingRoomName === 'MR3') {
        left = 'calc(((100% - 50px) / 3 * 2) + 50px)';
    }

    // Calculating height
    const timeFactor = timeDuration.hours() + timeDuration.minutes() / 60;
    const height = `calc(100% / 16 * ${timeFactor})`;

    // Calculating Width
    let width = 'calc(100% - 50px)';

    if (props.allMeetingRooms) {
        width = 'calc((100% - 50px) / 3)';
    }

    return {
        top,
        left,
        height,
        width,
    };
};
