import {
    GetUsersResponse,
    OfficeSpaceBookingListResponse,
    OfficeSpaceListResponse,
} from '../../../api/types';

export function mapBookingsToListData(data: OfficeSpaceBookingListResponse['data']) {
    return data.map((item) => ({
        bookingId: item.bookingId,
        startDate: item.startDate,
        endDate: item.endDate,
        user: {
            firstName: item.user.name,
            lastName: item.user.surname,
        },
        displayName: item.space.name,
    }));
}

export function mapUsersToSelectData(data: GetUsersResponse['data']) {
    return data.map((item) => ({
        label: `${item.name} ${item.surname}`,
        value: item.id,
    }));
}

export function mapOfficeSpacesToSelectData(data: OfficeSpaceListResponse['data']) {
    return data.map((item) => ({
        label: item.name,
        value: item.name,
    }));
}

export function mapBookingsToUsersSelect(data: OfficeSpaceBookingListResponse['data']) {
    const users = data.map((booking) => booking.user);
    const selectOptions = users.map((user) => ({
        label: `${user.name} ${user.surname}`,
        value: user.id,
    }));

    return selectOptions;
}
