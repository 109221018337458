/** @jsxImportSource @emotion/react */
import { FormControlLabel, FormGroup, Checkbox, css } from '@mui/material';

import React from 'react';
import {
    Control,
    Controller,
    FieldValues,
    Path,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';

type ControlledCheckboxGroupProps<T extends FieldValues> = {
    options: { label: string; value: string; color: string }[];
    name: Path<T>;
    watch: UseFormWatch<T>;
    setValue: UseFormSetValue<T>;
    control: Control<FieldValues, any>;
    disabled?: boolean;
};

const checkboxStyle = (color: string) =>
    css({
        display: 'flex',
        alignItems: 'center',

        '&::after': {
            content: '""',
            display: 'inline-block',
            width: '20px',
            height: '20px',
            backgroundColor: color,
            borderRadius: '3px',
            marginLeft: '10px',
        },
    });

export function ControlledCheckboxGroup<T extends FieldValues>({
    options,
    name,
    control,
    setValue,
    watch,
    disabled,
}: ControlledCheckboxGroupProps<T>) {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const value = event.target.value;

        const currentSelection = watch(name) || [];

        if (checked) {
            setValue(name, [...currentSelection, value] as any, {
                shouldDirty: true,
                shouldTouch: true,
            });
        } else {
            setValue(name, currentSelection.filter((item: any) => item !== value) as any, {
                shouldDirty: true,
                shouldTouch: true,
            });
        }
    };

    return (
        <FormGroup>
            {options.map((option) => (
                <FormControlLabel
                    label={option.label}
                    key={option.value}
                    disabled={disabled}
                    css={checkboxStyle(option.color)}
                    control={
                        <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    value={option.value}
                                    disabled={disabled}
                                    checked={field.value?.includes(option.value)}
                                    onChange={handleCheckboxChange}
                                />
                            )}
                        />
                    }
                />
            ))}
        </FormGroup>
    );
}
