/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import OfficeSpace from './OfficeSpace/OfficeSpace';

import styles from './styles';
import { useTelegram } from '../../../hooks/useTelegram';
import { MeetingRoom } from './MeetingRoom/MeetingRoom';
import { OfficeSpaceBookingListResponse, OfficeSpaceListResponse } from '../../../api/types';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getUserSelector, User } from '../../../store/reducers/user';
import { officeSpaceApi } from '../../../api/officeSpaceApi';
import { officeSpaceBookingApi } from '../../../api/officeSpaceBookingApi';
import { FullPageLoader } from '../FullPageLoader/FullPageLoader';

type OfficeMapProps = {
    config?: MapConfig;
    selectedSpace?: string;
    startDate?: string | null;
    endDate?: string | null;
    applyDefaultConfig?: boolean;
    onOfficeSpaceClick?: (value: string) => void;
    onMeetingRoomClick?: () => void;
};

export type MapConfig = {
    id: string;
    options: {
        disabled?: boolean;
        isBooked?: boolean;
        isMyBook?: boolean;
        render?: React.ReactNode | string;
        spaceTeams?: string[] | null;
    };
}[];

export function OfficeMap({
    config,
    selectedSpace,
    endDate,
    startDate,
    applyDefaultConfig,
    onOfficeSpaceClick,
    onMeetingRoomClick,
}: OfficeMapProps) {
    const [mapConfig, setMapConfig] = useState<MapConfig | undefined>(config);

    const [getOfficeSpaces, { isLoading: areOfficeSpacesLoading }] =
        officeSpaceApi.useLazyListQuery();
    const [getOfficeSpaceBookings, { isLoading: areBookingsLoading }] =
        officeSpaceBookingApi.useLazyListQuery();

    const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const { colorScheme } = useTelegram();
    const { events } = useDraggable(ref, { applyRubberBandEffect: true });

    const user = useAppSelector(getUserSelector);

    const fetchOfficeSpaceBookings = useCallback(async () => {
        if (startDate) {
            const response = await getOfficeSpaceBookings({
                filters: { startDate, endDate },
            }).unwrap();

            return response.data;
        }

        return undefined;
    }, [startDate]);

    const fetchOfficeSpaces = useCallback(async () => {
        const response = await getOfficeSpaces({}).unwrap();

        return response.data;
    }, []);

    useEffect(() => {
        if (applyDefaultConfig) {
            const initializeMapConfig = async () => {
                const officeSpaceBookings = await fetchOfficeSpaceBookings();
                const officeSpaces = await fetchOfficeSpaces();

                const initialMapConfig = mapResponseToMapConfig({
                    bookings: officeSpaceBookings,
                    officeSpaces,
                    user,
                });

                const combinedConfig = combineConfigs({
                    initialConfig: initialMapConfig,
                    userConfig: config,
                });

                setMapConfig(combinedConfig);
            };

            initializeMapConfig();
        }
    }, [fetchOfficeSpaceBookings, fetchOfficeSpaces, applyDefaultConfig, config]);

    if (areBookingsLoading || areOfficeSpacesLoading) {
        return <FullPageLoader />;
    }

    return (
        <div {...events} ref={ref} css={styles.officeMapWrapperStyles({ colorScheme })}>
            <div css={styles.headerWrapperStyles}>
                <div css={styles.topManagementWrapperStyles}>
                    <div css={styles.topManagementRoomStyles({ colorScheme })}>
                        <p>TOP M.</p>
                    </div>
                    <MeetingRoom
                        id="MR1"
                        onClick={onMeetingRoomClick}
                        {...retrieveOptionsFromConfig({ config: mapConfig, id: 'MR1' })}
                    />
                </div>
                <div css={styles.enterStyles({ colorScheme })}>
                    <p className="enterText">Enter</p>
                </div>
                <div css={styles.wcStyles({ colorScheme })}>W/C</div>
                <div css={styles.kitchenLawAccWrapperStyles}>
                    <div css={styles.kitchenStyles({ colorScheme })}>Kitchen</div>
                    <div css={styles.lawAccStyles({ colorScheme })}>
                        <p>L&A</p>
                    </div>
                    {['MR2', 'MR3'].map((id) => (
                        <MeetingRoom
                            key={id}
                            id={id}
                            onClick={onMeetingRoomClick}
                            {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                        />
                    ))}
                </div>
            </div>

            <div css={styles.wrapperStyles}>
                <div css={styles.firstColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['37', '38', '39'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['36', '35', '34'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.secondColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['30', '31', '32', '33'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['29', '28', '27', '26'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.thirdColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['22', '23', '24', '25'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['21', '20', '19', '18'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.fourthColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['15', '16', '17'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['14', '13', '12', '11'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.fifthColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['8', '9', '10'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['5', '6', '7'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.sixthColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['3', '4'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['2', '1'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div css={styles.windowWrapperStyles}>
                <div css={styles.windowStyles({ colorScheme })}>Window</div>
            </div>
        </div>
    );
}

function retrieveOptionsFromConfig({ config, id }: { config?: MapConfig; id?: string }) {
    const options = config?.find((record) => record.id === id);
    return options?.options;
}

function mapResponseToMapConfig({
    bookings,
    officeSpaces,
    user,
}: {
    user: User;
    bookings: OfficeSpaceBookingListResponse['data'] | undefined;
    officeSpaces: OfficeSpaceListResponse['data'];
}): MapConfig {
    const config: MapConfig = officeSpaces.map((officeSpace) => {
        const spaceBookings = bookings?.filter(
            (booking) => booking.space.name === officeSpace.name,
        );

        return {
            id: officeSpace.name,
            options: {
                disabled: !user.team
                    ? true
                    : user.team?.every((team) => !officeSpace.bookingTeams?.includes(team)),
                isBooked: spaceBookings && spaceBookings.length > 0,
                spaceTeams: officeSpace.bookingTeams?.map((team) => team),

                render:
                    spaceBookings && spaceBookings.length > 0
                        ? spaceBookings.map((booking) => (
                              <div key={booking.user.id}>
                                  <p>{booking.user.name}</p>
                                  <p>{booking.user.surname}</p>
                              </div>
                          ))
                        : undefined,
            },
        };
    });

    return config;
}

function combineConfigs({
    initialConfig,
    userConfig,
}: {
    initialConfig: MapConfig;
    userConfig: MapConfig | undefined;
}): MapConfig {
    if (!userConfig) {
        return initialConfig;
    }

    const combinedConfig: MapConfig = initialConfig.map((initialConfigRecord) => {
        const userConfigRecord = userConfig.filter((item) => item.id === initialConfigRecord.id)[0];

        if (!userConfigRecord) {
            return initialConfigRecord;
        }

        const combinedRecord = {
            id: initialConfigRecord.id,
            options: { ...initialConfigRecord.options, ...userConfigRecord.options },
        };

        return combinedRecord;
    });

    return combinedConfig;
}
